<template>
    <v-container class="ma-0 pa-0" fluid>
        <v-card class="ma-0" elevation="6" outlined>
            <v-card-text class="pa-0 ma-0">
                <v-tabs v-model="tabs">
                    <v-tab>
                        {{this.$t('erp.lang_baseData')}}
                    </v-tab>

                    <v-tab>
                        {{this.$t('settings.lang_personalData')}}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tabs">
                    <v-tab-item>
                        <personal-info-component :perm-group="this.PermGroups" :user-id="parseInt(this.$route.params.id)"
                                                 ref="personal" v-bind:form="this.form"/>
                    </v-tab-item>

                    <v-tab-item>
                        <job-information-component :roles="this.roles" @onFileChange="onFileChange" ref="jobinfo"
                                                   v-bind:form="this.form"/>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
            <v-card-actions class="text-center">
                <div class="text-center mx-auto">
                    <v-btn :disabled="this.tabs == 0" @click="--tabs" class="mr-2" icon>
                        <v-icon large>arrow_back_ios</v-icon>
                    </v-btn>
                    <v-btn :disabled="this.loading" :loading="this.loading" @click="save(0)" class="mx-auto"
                           color="primary"
                           large>
                        {{this.$t('generic.lang_save')}}
                    </v-btn>

                    <v-btn :disabled="this.loading || this.loadPDF" :loading="this.loading || this.loadPDF"
                           @click="generate(-2 , true)"
                           class="mx-auto ml-2"
                           color="warning"
                           large
                           v-if="this.tabs == 1">
                        {{this.$t('generic.lang_download')}}
                    </v-btn>

                    <v-btn :disabled="this.tabs == 1" @click="++tabs" class="ml-2" icon>
                        <v-icon large>arrow_forward_ios</v-icon>
                    </v-btn>
                </div>
            </v-card-actions>

            <v-card-text class="ma-0 pa-0 d-none" style="width: 100%">
                <export-component ref="pdf" style="width: 100%" v-bind:data="this.form" v-bind:system="this.system"
                                  v-if="this.tabs == 1"/>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
    import PersonalInfoComponent from "./editUser/PersonalInfoComponent";
    import {ENDPOINTS} from "../../../../config";
    import {Events} from "../../../../plugins/events";
    import JobInformationComponent from "./editUser/JobInformationComponent";
    import ExportComponent from "./editUser/ExportComponent";
    import {mapState} from "vuex";

    export default {
        name: "EditUserComponent",
        components: {ExportComponent, JobInformationComponent, PersonalInfoComponent},
        data: () => {
            return {
                ENDPOINTS,
                tabs: 0,
                avatar: "",
                form: {
                    editUserArchiveEnabled: false,
                    severelyDisabled: 0,
                    employmentContract: 0,
                    certificateVATDeduction: 0,
                    SVID: 0,
                    hasWhatsapp: 0,
                    hasHealthInsurance: 0,
                    overrideCashierId: null,
                    taxableInformation: [],
                    isDeliveryProvider:false,
                    department: "",
                    avatar: "",
                    dispensingKey:'',
                },
                PermGroups: [],
                loading: false,
                roles: null,
                system: {},
                loadPDF: false,
            }
        },
        computed: {
            ...mapState([
                'api',
            ])
        },
      mounted() {
            this.tabs = localStorage.getItem('complete') !== null ? 1 : 0;
            this.getData();
            localStorage.removeItem('complete');
            this.getSystem();
        },
        beforeDestroy() {
            localStorage.removeItem('complete')
        },
        methods: {
            getSystem() {
                this.axios.post(ENDPOINTS.SETTINGS.BASIC.GET).then((res) => {
                    this.system = res.data.formfillData.textFields;
                }).catch((err) => {
                    //console.log(err.message);
                })
            },
            generate() {
                this.loadPDF = true;
                let self = this, base64 = this.$refs.pdf.Base64();
                let timer = setTimeout(function () {
                    self.axios.post(this.$store.getters['api/auth/pdfServer'] + '/template/', {
                            htmlTemplate: base64,
                            pdfData: {data: self.form, system: self.system}
                        },
                        {
                            responseType: 'arraybuffer',
                            headers: {
                                'POSID': self.api.auth.posID,
                                'ACCESSSTRING': self.api.auth.accessString,
                                'APITOKEN': self.api.auth.apiWebToken,
                            }
                        }
                    ).then((res) => {
                        const blobContent = new Blob([res.data], {type: "application/pdf"});
                        let a = document.createElement('a');
                        a.href = URL.createObjectURL(blobContent);
                        a.setAttribute('target', '_blank');
                        a.click();
                        a.remove();
                    }).catch((err) => {
                        //console.log(err.message);
                    }).finally(() => {
                        self.loadPDF = false;
                        clearTimeout(timer)
                    })
                }, 3000)
            },
            onFileChange(e) {
                this.avatar = e;
            },
            save(tab, download = false) {
                if (!this.tabs) {
                    if (!this.$refs.personal.validate()) return;
                } else {
                    if (!this.$refs.jobinfo.validate()) return;
                }

                let f = new FormData();
                f.append('userID', this.$route.params.id);

                for (let key in this.form) {
                    if (this.form.hasOwnProperty(key) && (this.form === "" || this.form === null)) {
                        delete this.form[key];
                    } else if (key === "avatar") {
                        f.append(key, this.avatar)
                    } else if (key === "taxableInformation") {
                        let hasValues = [];
                        if (this.form[key] && this.form[key].length) {
                            hasValues = this.form[key].filter((elt) => (elt.from !== "") && (elt.to !== "") && (elt.employmentType !== "") && (elt.employmentDaysNumber !== ""))
                        }
                        this.form[key] = hasValues;
                        f.append(key, JSON.stringify(hasValues));
                    } else
                        f.append(key, this.form[key])
                }


                this.loading = true;
                this.form.userID = this.$route.params.id;
                this.form.download = tab == -1;

                this.axios.post(ENDPOINTS.SETTINGS.USER.UPDATE, f).then((res) => {
                    if (res.data.status === 'SUCCESS') {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_actionSuccessful'),
                            color: "success"
                        });

                        if (download == true) {
                            this.generate();
                        }
                    } else if (res.data.status === "FAILED") {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch((err) => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                }).finally(() => {
                    this.loading = false;
                })
            },
            getData() {
                this.axios.post(ENDPOINTS.SETTINGS.USER.GET, {
                    userID: this.$route.params.id
                }).then((res) => {
                    this.form = res.data.userData;
                    this.roles = res.data.userRoles;
                    this.form.editUserUserPermGroup = res.data.userActivePermGrp;
                    this.form.editUserUserPermGroup = res.data.userActivePermGrp;
                    this.form.role = res.data.userActiveRole;


                    res.data.permGrp.forEach((row) => {
                        this.PermGroups.push({name: row[1], id: parseInt(row[0])});
                    });
                }).catch(() => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                });
            }
        }
    }
</script>

<style scoped>

</style>
