<template>
    <v-container class="main-card mb-3 pa-4 card" fluid id="pdf" style="width: 100%">
        <div class="menuWidth" style="width: 100%;">
            <div class="main-card card" style="padding: 50px 45px 50px 45px; border: none">
                <div class="cc">
                    <div class="row">
                        <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8" style="width: 90%;">
                            <h3>Personalfragebogen</h3>
                            <span class="text-secondary">(grau hinterlegte Felder sind vom Arbeitgeber auszufüllen)</span>
                        </div>

                        <div class="col-sm-4 col-md-4 col-lg-4col-xl-4 text-right"
                             style="width: 100%">
                            <p class="mx-auto">SYS LOGO</p>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-lg-12 col-xl-12">
                            <span>Firm:</span> <strong>{{this.system.settings_SystemName}}</strong>
                        </div>
                    </div>

                    <div class="row mt-3 border-bottom pt-0 pb-0 mx-0 border-secondary">
                        <div class="col-lg-6 col-xl-6 col-sm-6 pl-0 pr-0 col-md-6 col-6">
                            <h6>Name des Mitarbeiters</h6>
                            <span class="text-secondary">Omar IZEM</span>
                        </div>

                        <div class="col-lg-6 col-xl-6 col-sm-6 pl-0 pr-0 col-md-6 col-6 text-right">
                            <h6>Personalnummer</h6>
                            <span class="text-secondary">Omar IZEM</span>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-12 col-md-12 pb-0 mb-0 col-lg-12 col-xl-12">
                            <h5>Pers&ouml;nliche Angaben</h5>
                        </div>

                        <div class="col-12 col-md-12">
                            <table class="table-bordered" width="100%">
                                <tbody>
                                <tr>
                                    <td colspan="6">
                                        <p><span style="font-weight: 400;">Familienname: </span><strong>{{this.data.editUserLastName}}</strong><br>
                                            <span style="font-weight: 400;">ggf. Geburtsname</span>
                                        </p>
                                    </td>
                                    <td colspan="7">
                                        <p><span style="font-weight: 400;">Vorname: </span><strong>{{this.data.editUserPreName}}</strong>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <p><span style="font-weight: 400;">Stra&szlig;e und Hausnummer: </span><strong>{{this.data.street
                                            + " - " + this.data.houseNumber}}</strong><br/>
                                            <span style="font-weight: 400;">inkl. Anschriftenzusatz</span>
                                        </p>
                                    </td>
                                    <td colspan="7">
                                        <p><span
                                                style="font-weight: 400;">PLZ, Ort: </span><strong>{{this.data.zip}}</strong>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <p><span style="font-weight: 400;">Geburtsdatum: </span><strong>{{this.data.birthday}}</strong>
                                        </p>
                                    </td>
                                    <td colspan="5">
                                        <p><span style="font-weight: 400;">Geschlecht</span></p>
                                    </td>
                                    <td colspan="2">
                                        <p><span id="male" style="font-weight: 400;padding: 3px"></span><span
                                                style="font-weight: 400;padding: 3px">m&auml;nnlich</span>
                                            <span id="female" style="font-weight: 400;padding: 3px"></span><span
                                                    style="font-weight: 400;padding: 3px"> weiblich</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <p><span
                                                style="font-weight: 400;">Versicherungsnummer gem. Sozialvers.Ausweis: </span><strong>{{this.data.insuranceNumber}}</strong>
                                        </p>
                                    </td>
                                    <td colspan="7">
                                        <p><span style="font-weight: 400;">Familienstand: </span><strong>{{this.data.maritalStatus}}</strong>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <p><span style="font-weight: 400;">Geburtsort, -land &ndash; nur bei fehlender Versicherungs-Nr: </span><strong>{{this.data.placeOfBirth}}</strong>
                                        </p>
                                    </td>
                                    <td colspan="5">
                                        <p><span style="font-weight: 400;">Schwerbehindert: </span></p>
                                    </td>
                                    <td colspan="2">
                                        <p><span id="severelyDisabledTrue" style="font-weight: 400;padding: 3px"></span><span
                                                style="font-weight: 400;padding: 3px">ja</span>
                                            <span id="severelyDisabledFalse"
                                                  style="font-weight: 400;padding: 3px"></span><span
                                                    style="font-weight: 400;padding: 3px">nein</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6">
                                        <p><span
                                                style="font-weight: 400;">Staatsangeh&ouml;rigkeit: </span><strong>{{this.data.nationality}}</strong>
                                        </p>
                                    </td>
                                    <td colspan="7">
                                        <p><span style="font-weight: 400;">Konto-Nr.(IBAN)/ BIC/ Bank: </span><strong>{{this.data.IBAN
                                            + "/" + this.data.BIC + "/" + this.data.BANK}}</strong></p>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>

                        <div class="col-12 col-md-12 pb-0 mb-0 col-lg-12 col-xl-12">
                            <h5>Besch&auml;ftigung</h5>
                        </div>
                        <div class="col-12 col-md-12">
                            <table class="table-bordered" width="100%">
                                <tbody>
                                <tr>
                                    <td colspan="6">
                                        <p><span style="font-weight: 400;">Eintrittsdatum : </span><strong>{{this.data.entryDate}}</strong>
                                        </p>
                                    </td>
                                    <td colspan="7">
                                        <p><span style="font-weight: 400;">☐ </span><span style="font-weight: 400;">Hauptbesch&auml;ftigung</span>
                                        </p>
                                        <p><span style="font-weight: 400;">☐ </span><span style="font-weight: 400;">Nebenbesch&auml;ftigung</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="13">
                                        <p><span style="font-weight: 400;">&Uuml;ben Sie weitere Besch&auml;ftigungen aus?  </span>
                                            <span id="moreOccupationTrue"
                                                  style="font-weight: 400;padding: 3px"> </span>
                                            <span style="padding: 3px"> ja </span>
                                            <span id="moreOccupationFalse"
                                                  style="font-weight: 400;padding: 3px"> </span>
                                            <span style="padding: 3px"> nein </span>
                                        </p>
                                        <p><span style="font-weight: 400;">Handelt es sich hierbei um eine geringf&uuml;gige Besch&auml;ftigung? </span>
                                            <span id="marginalJobTrue"
                                                  style="font-weight: 400;padding: 3px"> </span>
                                            <span style="padding: 3px"> ja </span>
                                            <span id="marginalJobFalse" style="font-weight: 400;padding: 3px"> </span>
                                            <span style="padding: 3px"> nein </span>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p><span style="font-weight: 400;">H&ouml;chster Schulabschluss</span>
                                        </p>
                                    </td>
                                    <td colspan="5">
                                        <p><span id="highestSchol1" style="font-weight: 400;"></span><span
                                                style="font-weight: 400;"> ohne Schulabschluss</span>
                                        </p>
                                        <p><span id="highestSchol2" style="font-weight: 400;"></span><span
                                                style="font-weight: 400;"> Haupt-/Volksschulabschluss</span>
                                        </p>
                                        <p><span id="highestSchol3" style="font-weight: 400;"></span><span
                                                style="font-weight: 400;"> Mittlere Reife/gleichwertiger</span><span
                                                style="font-weight: 400;"><br/></span><span style="font-weight: 400;"> &nbsp; &nbsp; Abschluss</span>
                                        </p>
                                        <p><span id="highestSchol4" style="font-weight: 400;"></span><span
                                                style="font-weight: 400;"> Abitur/Fachabitur</span>
                                        </p>
                                    </td>
                                    <td><br/><br/>
                                        <p><span style="font-weight: 400;">H&ouml;chste Berufs-ausbildung</span></p>
                                    </td>
                                    <td colspan="6">
                                        <p><span id="highestVocationalEducation1" style="font-weight: 400;"></span><span
                                                style="font-weight: 400;"> ohne beruflichen Ausbildungsabschluss</span>
                                        </p>
                                        <p><span id="highestVocationalEducation2" style="font-weight: 400;"></span><span
                                                style="font-weight: 400;"> Anerkannte Berufsausbildung</span>
                                        </p>
                                        <p><span id="highestVocationalEducation3" style="font-weight: 400;"></span><span
                                                style="font-weight: 400;"> Meister/Techniker/gleichwertiger</span><span
                                                style="font-weight: 400;"><br/></span><span style="font-weight: 400;"> &nbsp; &nbsp; Fachschulabschluss</span>
                                        </p>
                                        <p><span id="highestVocationalEducation4" style="font-weight: 400;"></span><span
                                                style="font-weight: 400;"> Bachelor</span>
                                        </p>
                                        <p><span id="highestVocationalEducation5" style="font-weight: 400;"></span><span
                                                style="font-weight: 400;"> Diplom/Magister/ Master/Staatsexamen</span>
                                        </p>
                                        <p><span id="highestVocationalEducation6" style="font-weight: 400;"></span><span
                                                style="font-weight: 400;"> Promotion</span>
                                        </p>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-12 col-md-12 pb-0 mb-0 col-lg-12 col-xl-12">
                            <h5>Bescheinigungen elektronisch annehmen (Bea)</h5>
                        </div>
                        <div class="col-12 col-md-12">
                            <table class="table-bordered" width="100%">
                                <tbody>
                                <tr>
                                    <td colspan="13">
                                        <p><span style="font-weight: 400;">Mit der elektronischen &Uuml;bermittlung von Arbeits- oder Nebeneinkommensbescheinigungen an die Bundesagentur f&uuml;r Arbeit erkl&auml;re ich mich einverstanden.&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span>
                                            <span id="agreeCertificatElectronicTrue"
                                                  style="font-weight: 400;padding: 3px"></span>
                                            <span style="font-weight: 400;padding: 3px">ja </span>
                                            <span id="agreeCertificatElectronicFalse"
                                                  style="font-weight: 400;padding: 3px"></span><span
                                                    style="font-weight: 400;padding: 3px">nein</span></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="13">
                                        <p><strong>Steuer</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="3">
                                        <p><span style="font-weight: 400;">Identifikationsnr: </span><strong>{{this.data.taxIdentificationNumber}}</strong>
                                        </p>
                                    </td>
                                    <td colspan="2">
                                        <p><span
                                                style="font-weight: 400;">Steuerklasse/ Faktor: </span><strong>{{this.data.taxBracketFactor}}</strong>
                                        </p>
                                    </td>
                                    <td colspan="5">
                                        <p><span style="font-weight: 400;">Kinderfreibetr&auml;ge: </span><strong>{{this.data.childAllowances}}</strong>
                                        </p>
                                    </td>
                                    <td colspan="3">
                                        <p><span style="font-weight: 400;">Konfession: </span><strong>{{this.data.denomination}}</strong>
                                        </p>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="col-12 col-md-12 pb-0 mb-0 col-lg-12 col-xl-12">
                            <h5>Sozialversicherung</h5>
                        </div>
                        <div class="col-12 col-md-12">
                            <table class="table-bordered" width="100%">
                                <tbody>
                                <tr>
                                    <td colspan="5">
                                        <p><span style="font-weight: 400;">Gesetzl. Krankenkasse (bei PKV: letzte ges. Krankenkasse)</span>
                                        </p>
                                    </td>
                                    <td colspan="8">
                                        <p><span style="font-weight: 400;">Elterneigenschaft: </span><span
                                                id="parentalTrue" style="font-weight: 400;padding: 3px"></span><span
                                                style="font-weight: 400;">ja</span>
                                            <span id="parentalFalse" style="font-weight: 400;padding: 3px"></span><span
                                                    style="font-weight: 400;padding: 3px">nein</span>
                                        </p>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="col-12 col-md-12 pb-0 mb-0 col-lg-12 col-xl-12">
                            <h5>Angaben zu den Arbeitspapieren</h5>
                        </div>
                        <div class="col-12 col-md-12">
                            <table class="table-bordered" width="100%">
                                <tbody>

                                <tr>
                                    <td colspan="8">
                                        <p><span style="font-weight: 400;">Arbeitsvertrag</span></p>
                                    </td>
                                    <td colspan="5">
                                        <p><span id="angabenzu1" style="padding: 3px"></span><span
                                                style="font-weight: 400;padding: 3px"> liegt vor</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="8">
                                        <p><span style="font-weight: 400;">Bescheinigung &uuml;ber LSt.-Abzug (bei ausl. AN ohne St.-ID)</span>
                                        </p>
                                    </td>
                                    <td colspan="5">
                                        <p><span id="angabenzu2" style="padding: 3px"></span><span
                                                style="font-weight: 400;padding: 3px"> liegt vor</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="8">
                                        <p><span style="font-weight: 400;">SV-Ausweis</span></p>
                                    </td>
                                    <td colspan="5">
                                        <p><span id="angabenzu3" style="padding: 3px"></span><span
                                                style="font-weight: 400;padding: 3px"> liegt vor</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="8">
                                        <p><span style="font-weight: 400;">Bescheinigung zur privaten Krankenversicherung</span>
                                        </p>
                                    </td>
                                    <td colspan="5">
                                        <p><span id="angabenzu4" style="padding: 3px"></span><span
                                                style="font-weight: 400;padding: 3px"> liegt vor</span>
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="8">
                                        <p><span style="font-weight: 400;">Nachweis Elterneigenschaft</span></p>
                                    </td>
                                    <td colspan="5">
                                        <p><span id="angabenzu5" style="padding: 3px"></span><span
                                                style="font-weight: 400;padding: 3px"> liegt vor</span>
                                        </p>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>

                        <div class="col-12 col-md-12 pb-0 mb-0 col-lg-12 col-xl-12">
                            <h5>Angaben zu steuerpflichtigen kurzfristigen Vorbesch&auml;ftigungszeiten
                                im laufenden Kalenderjahr</h5>
                        </div>
                        <div class="col-12 col-md-12">
                            <table class="table-bordered" width="100%">
                                <thead>
                                <tr>
                                    <th >
                                        <p><span style="font-weight: 400;">Zeitraum von</span></p>
                                    </th>
                                    <th >
                                        <p><span style="font-weight: 400;">Zeitraum bis</span></p>
                                    </th>
                                    <th>
                                        <p><span style="font-weight: 400;">Art der Besch&auml;ftigung</span></p>
                                    </th>
                                    <th>
                                        <p><span style="font-weight: 400;">Anzahl der Besch&auml;ftigungstage</span>
                                        </p>
                                    </th>
                                </tr>
                                </thead>

                                <tbody id="experience">

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
    import VueHtml2pdf from 'vue-html2pdf'
    import {mapState} from "vuex";
    import Tables from "../../../../pos/Tables";

    export default {
        name: "ExportComponent",
        props: {
            data: Object,
            system: Object
        },
        mounted() {
            let male = document.createElement('input'),
                female = document.createElement('input'),
                severelyDisabledTrue = document.createElement('input'),
                severelyDisabledFalse = document.createElement('input'),
                moreOccupationFalse = document.createElement('input'),
                moreOccupationTrue = document.createElement('input'),
                marginalJobTrue = document.createElement('input'),
                marginalJobFalse = document.createElement('input'),
                highestSchol1 = document.createElement('input'),
                highestSchol2 = document.createElement('input'),
                highestSchol3 = document.createElement('input'),
                highestSchol4 = document.createElement('input'),
                highestVocationalEducation1 = document.createElement('input'),
                highestVocationalEducation2 = document.createElement('input'),
                highestVocationalEducation3 = document.createElement('input'),
                highestVocationalEducation4 = document.createElement('input'),
                highestVocationalEducation5 = document.createElement('input'),
                highestVocationalEducation6 = document.createElement('input'),
                agreeCertificatElectronicTrue = document.createElement('input'),
                agreeCertificatElectronicFalse = document.createElement('input'),
                parentalFalse = document.createElement('input'),
                parentalTrue = document.createElement('input'),
                angabenzu1 = document.createElement('input'),
                angabenzu2 = document.createElement('input'),
                angabenzu3 = document.createElement('input'),
                angabenzu4 = document.createElement('input'),
                angabenzu5 = document.createElement('input'),
                angabenzu6 = document.createElement('input');

            //set angabenzu1 checkbox
            angabenzu1.setAttribute("type", "checkbox");
            if (parseInt(this.data.employmentContract) == 1)
                angabenzu1.setAttribute("checked", "checked");
            document.querySelector("#angabenzu1").append(angabenzu1)

            //set angabenzu2 checkbox
            angabenzu2.setAttribute("type", "checkbox");
            if (parseInt(this.data.certificateVATDeduction) == 1)
                angabenzu2.setAttribute("checked", "checked");
            document.querySelector("#angabenzu2").append(angabenzu2)

            //set angabenzu3 checkbox
            angabenzu3.setAttribute("type", "checkbox");
            if (parseInt(this.data.SVID) == 1)
                angabenzu3.setAttribute("checked", "checked");
            document.querySelector("#angabenzu3").append(angabenzu3)

            //set angabenzu4 checkbox
            angabenzu4.setAttribute("type", "checkbox");
            if (parseInt(this.data.hasHealthInsurance) == 1)
                angabenzu4.setAttribute("checked", "checked");
            document.querySelector("#angabenzu4").append(angabenzu4)

            //set angabenzu5 checkbox
            angabenzu5.setAttribute("type", "checkbox");
            if (parseInt(this.data.hasHealthInsurance) == 1)
                angabenzu5.setAttribute("checked", "checked");
            document.querySelector("#angabenzu5").append(angabenzu5)

            //set angabenzu6 checkbox
            // angabenzu6.setAttribute("type", "checkbox");
            // if (parseInt(this.data.proofParentalStatus) == 1)
            //     angabenzu6.setAttribute("checked", "checked");
            // document.querySelector("#angabenzu6").append(angabenzu6)

            //set male checkbox
            male.setAttribute("type", "checkbox");
            if ((this.data.editUserSalutation === "m"))
                male.setAttribute("checked", "checked");
            document.querySelector("#male").append(male)

            //set female checkbox
            female.setAttribute("type", "checkbox");
            if ((this.data.editUserSalutation === "mrs"))
                female.setAttribute("checked", "checked");
            document.querySelector("#female").append(female)

            //set  severelyDisabledFalse checkbox
            severelyDisabledFalse.setAttribute("type", "checkbox");
            if ((parseInt(this.data.severelyDisabled) == 0))
                severelyDisabledFalse.setAttribute("checked", "checked");
            document.querySelector("#severelyDisabledFalse").append(severelyDisabledFalse)

            //set  severelyDisabledTrue checkbox
            severelyDisabledTrue.setAttribute("type", "checkbox");
            if ((parseInt(this.data.severelyDisabled) == 1))
                severelyDisabledTrue.setAttribute("checked", "checked");
            document.querySelector("#severelyDisabledTrue").append(severelyDisabledTrue)

            //set moreOccupationFalse checkbox
            moreOccupationFalse.setAttribute("type", "checkbox");
            if ((parseInt(this.data.moreOccupation) == 0))
                moreOccupationFalse.setAttribute("checked", "checked");
            document.querySelector("#moreOccupationFalse").append(moreOccupationFalse);

            //set moreOccupationTrue checkbox
            moreOccupationTrue.setAttribute("type", "checkbox");
            if ((parseInt(this.data.moreOccupation) == 1))
                moreOccupationTrue.setAttribute("checked", "checked");
            document.querySelector("#moreOccupationTrue").append(moreOccupationTrue)


            //set moreOccupationFalse checkbox
            marginalJobTrue.setAttribute("type", "checkbox");
            if ((parseInt(this.data.marginalJob) == 1))
                marginalJobTrue.setAttribute("checked", "checked");
            document.querySelector("#marginalJobTrue").append(marginalJobTrue);

            //set moreOccupationTrue checkbox
            marginalJobFalse.setAttribute("type", "checkbox");
            if ((parseInt(this.data.marginalJob) == 0))
                marginalJobFalse.setAttribute("checked", "checked");
            document.querySelector("#marginalJobFalse").append(marginalJobFalse)


            //highestSchol checkboxes
            //set highestSchol1 checkbox
            highestSchol1.setAttribute("type", "checkbox");
            if (this.data.highestScholDegree === this.highestSchol[0])
                highestSchol1.setAttribute("checked", "checked");
            document.querySelector("#highestSchol1").append(highestSchol1)

            //set highestSchol2 checkbox
            highestSchol2.setAttribute("type", "checkbox");
            if (this.data.highestScholDegree === this.highestSchol[1])
                highestSchol2.setAttribute("checked", "checked");
            document.querySelector("#highestSchol2").append(highestSchol2)

            //set highestSchol3 checkbox
            highestSchol3.setAttribute("type", "checkbox");
            if (this.data.highestScholDegree === this.highestSchol[2])
                highestSchol3.setAttribute("checked", "checked");
            document.querySelector("#highestSchol3").append(highestSchol3)

            //set highestSchol4 checkbox
            highestSchol4.setAttribute("type", "checkbox");
            if (this.data.highestScholDegree === this.highestSchol[3])
                highestSchol4.setAttribute("checked", "checked");
            document.querySelector("#highestSchol4").append(highestSchol4)

            //highestVocationalEducation checkboxes
            //set highestVocationalEducation1 checkbox
            highestVocationalEducation1.setAttribute("type", "checkbox");
            if (this.data.highestVocationalEducation === this.highestVocationalEducation[0])
                highestVocationalEducation1.setAttribute("checked", "checked");
            document.querySelector("#highestVocationalEducation1").append(highestVocationalEducation1)

            //set highestVocationalEducation2 checkbox
            highestVocationalEducation2.setAttribute("type", "checkbox");
            if (this.data.highestVocationalEducation === this.highestVocationalEducation[1])
                highestVocationalEducation2.setAttribute("checked", "checked");
            document.querySelector("#highestVocationalEducation2").append(highestVocationalEducation2)

            //set highestVocationalEducation3 checkbox
            highestVocationalEducation3.setAttribute("type", "checkbox");
            if (this.data.highestVocationalEducation === this.highestVocationalEducation[2])
                highestVocationalEducation3.setAttribute("checked", "checked");
            document.querySelector("#highestVocationalEducation3").append(highestVocationalEducation3)

            //set highestVocationalEducation4 checkbox
            highestVocationalEducation4.setAttribute("type", "checkbox");
            if (this.data.highestVocationalEducation === this.highestVocationalEducation[3])
                highestVocationalEducation4.setAttribute("checked", "checked");
            document.querySelector("#highestVocationalEducation4").append(highestVocationalEducation4)

            //set highestVocationalEducation5 checkbox
            highestVocationalEducation5.setAttribute("type", "checkbox");
            if (this.data.highestVocationalEducation === this.highestVocationalEducation[4])
                highestVocationalEducation5.setAttribute("checked", "checked");
            document.querySelector("#highestVocationalEducation5").append(highestVocationalEducation5)

            //set highestVocationalEducation6 checkbox
            highestVocationalEducation6.setAttribute("type", "checkbox");
            if (this.data.highestVocationalEducation === this.highestVocationalEducation[5])
                highestVocationalEducation6.setAttribute("checked", "checked");
            document.querySelector("#highestVocationalEducation6").append(highestVocationalEducation6)

            //set agreeCertificatElectronicFalse checkbox
            agreeCertificatElectronicFalse.setAttribute("type", "checkbox");
            if ((parseInt(this.data.agreeCertificatElectronic) == 0))
                agreeCertificatElectronicFalse.setAttribute("checked", "checked");
            document.querySelector("#agreeCertificatElectronicFalse").append(agreeCertificatElectronicFalse)

            //set agreeCertificatElectronicTrue checkbox
            agreeCertificatElectronicTrue.setAttribute("type", "checkbox");
            if ((parseInt(this.data.agreeCertificatElectronic) == 1))
                agreeCertificatElectronicTrue.setAttribute("checked", "checked");
            document.querySelector("#agreeCertificatElectronicTrue").append(agreeCertificatElectronicTrue);

            //set parentalTrue checkbox
            parentalTrue.setAttribute("type", "checkbox");
            if ((parseInt(this.data.parental) == 1))
                parentalTrue.setAttribute("checked", "checked");
            document.querySelector("#parentalTrue").append(parentalTrue)

            //set parentalFalse checkbox
            parentalFalse.setAttribute("type", "checkbox");
            if ((parseInt(this.data.parental) == 0))
                parentalFalse.setAttribute("checked", "checked");
            document.querySelector("#parentalFalse").append(parentalFalse);


            this.data.taxableInformation.forEach((elt) => {
                let tr = document.createElement('tr'),
                    td1 = document.createElement('td'),
                    td2 = document.createElement('td'),
                    td3 = document.createElement('td'),
                    td4 = document.createElement('td');

                td1.innerHTML = elt.from;
                td2.innerHTML = elt.to;
                td3.innerHTML = elt.employmentType;
                td4.innerHTML = elt.employmentDaysNumber;

                tr.append(td1);
                tr.append(td2);
                tr.append(td3);
                tr.append(td4);

                document.querySelector('#experience').append(tr);
            });

        },
        data: () => {
            return {
                highestSchol: [
                    "ohne Schulabschluss",
                    "Haupt-/Volksschulabschluss",
                    "Mittlere Reife/gleichwertiger Abschluss",
                    "Abitur/Fachabitur"
                ],
                highestVocationalEducation: [
                    "ohne beruflichen Ausbildungsabschluss",
                    "Anerkannte Berufsausbildung",
                    "Meister/Techniker/gleichwertiger Fachschulabschluss",
                    "Bachelor",
                    "Diplom/Magister/ Master/Staatsexamen",
                    "Promotion"
                ]
            }
        },
        components: {
            Tables,
            VueHtml2pdf
        },
        computed: {
            ...mapState([
                'api',
            ])
        },
        methods: {
            Base64() {
                let html = '<html style="width: 100%; margin: 0; padding: 0"><head><meta charset="utf-8"><meta content="IE=edge" http-equiv="X-UA-Compatible"><link crossorigin="anonymous" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css" integrity="sha384-9aIt2nRpC12Uk9gS9baDl411NQApFmC26EwAOH8WgZl5MYYxFfc+NcPb1dKGj7Sk" rel="stylesheet"><title>pdf-server template</title></head><body style="width: 100%; margin: 0; padding: 0">';
                html += document.getElementById('pdf').innerHTML;
                html += "</body></html>";
                
                const tbodyCells = document.querySelectorAll('.table-bordered > tbody > tr >td');
                tbodyCells.forEach((elt) => {
                    elt.style.padding = "5px 5px 0 5px";
                });

                const theadCells = document.querySelectorAll('.table-bordered > tbody > tr >th');
                theadCells.forEach((elt) => {
                    elt.style.padding = "5px 5px 0 5px";
                });

                return window.btoa(unescape(encodeURIComponent(html)));
            },
            hasGenerated(event) {

            },
            hasStartedGeneration() {
            },
            onProgress(event) {

            }
        }
        ,
    }
</script>

<style scoped>
    .menuWidth {
        max-width: 1200px;
        margin: auto;
        margin-bottom: -22px;
    }

    .table-bordered > tbody > tr > td, .table-bordered > tbody > tr > th {
        padding: 10px;
    }
</style>