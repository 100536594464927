<template>
    <div>
        <page-title :heading="$t('settings.lang_user_edit') " :icon=icon
                    :subheading="$t('settings.lang_user_edit')" show-previous-button url-previous="/settings/basicSettings/displayUsers"></page-title>
        <div class="app-main__inner">
            <edit-user-component/>
        </div>
    </div>
</template>

<script>
    import PageTitle from "../../../../Layout/Components/PageTitle.vue";
    import EditUserComponent from "../../../../components/settings/basic/users/EditUserComponent";

    export default {
        components: {
            EditUserComponent,
            PageTitle
        },
        props: ["id"],
        data: () => ({
            icon: 'pe-7s-users icon-gradient bg-tempting-azure',
        })
    }
</script>